import model from './model'

export default model.createController(({$widget}) => {
  return {
    pageReady: async () => {
      console.log('hey!')
      $widget.fireEvent('widgetLoaded', {})
    },
    exports: {},
  }
})
